<template>
  <div>
    <NavBar
      :fixed="true"
      :title="$t('mine.serviceTools.message')"
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <JList
      tabKey="name"
      list
      tabVal="value"
      class="list_wrap"
      :tabs="tabs"
      paramKey="status"
      url="/users/message"
      tabBottomBorder
      customList
      :refresh="refresh"
      :nullImg="verConfig.messageNullPng"
    >
      <template v-slot:all="row">
        <MSGItem :data="row.data" @read="read" />
        <!-- <div
          @click="read(row.data)"
          class="top1 hidden padding12 bg-fff item_wrap"
          :class="row.data.reader == 0 ? 'unread' : ''"
          style="border-bottom: calc(1rem / 32) solid var(--bg)"
        >
          <div class="flex flex-center-ver top8">
            <div class="unread_wrap" v-if="row.data.reader == 0"></div>
            <div class="font38 title">
              {{ row.data.title }}
            </div>
          </div>

          <div class="font28 top10 color-707070">
            {{
              $format(row.data.update || row.data.indate,verConfig.dateFormat || "MM/dd/yyyy HH:mm:ss")
            }}
          </div>
          <div class="top12 font32">
            <TextBr :text="row.data.content"></TextBr>
          </div>
          <div class="top12"></div>
        </div> -->
      </template>
      <template v-slot:unread="row">
        <MSGItem :data="row.data" @read="read" />
        <!-- <div
          class="top1 hidden padding12 bg-fff item_wrap"
          style="border-bottom: calc(1rem / 32) solid var(--bg)"
        >
          <div class="flex flex-center-ver top8">
            <div class="unread_wrap" v-if="row.data.reader == 0"></div>
            <div class="font38">
              {{ row.data.title }}
            </div>
          </div>
          <div class="font28 top10 color-707070">
            {{
              $format(
                row.data.update || row.data.indateindate,
                verConfig.dateFormat || "MM/dd/yyyy HH:mm:ss"
              )
            }}
          </div>
          <div class="top12 font32">
            <TextBr :text="row.data.content"></TextBr>
          </div>
          <div class="top12"></div>
        </div> -->
      </template>
      <template v-slot:null>
        <div class="null">
          <VanImage
            :src="verConfig.messageNullPng || verConfig.nullPng || noDataBack"
            mode="aspectFit"
          />
        </div>
      </template>
    </JList>
  </div>
</template>

<script>
import JList from "@/components/j-list.vue";
import TextBr from "@/components/br.vue";
import MSGItem from "./components/messageItem.vue";
import { NavBar, Image as VanImage } from "vant";
export default {
  components: {
    JList,
    TextBr,
    NavBar,
    MSGItem,
    VanImage
  },
  data() {
    this.tabs = [
      { name: this.$t("noticeList.all"), value: "all" },
      { name: this.$t("noticeList.unread"), value: "unread" },
    ];
    return {
      refresh: 0,
    };
  },
  methods: {
    read(item) {
      this.$api.readNotice(item.id, (res) => {
        this.refresh = new Date().getTime();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../components/common.css");
.unread_wrap {
  margin-right: calc(12rem / 32);
  width: calc(20rem / 32);
  height: calc(20rem / 32);
  /* background-color: var(--err); */
  border-radius: 50%;
}
@paddingRight: 20px;
.item_wrap {
  padding: 12px @paddingRight;
  // margin-bottom: 12px;
  margin-top: 8px;
  position: relative;
  .title {
    font-weight: bold;
    color: #256dcd !important;
  }
}
.unread {
  &::after {
    content: "";
    display: block;
    @size: 6px;
    width: @size;
    height: @size;
    border-radius: 50%;
    background-color: #f00;
    position: absolute;
    right: @paddingRight;
    top: 50%;
  }
}
.null{
  // width: 40vw;
  padding: calc(50rem / 16);
}
// .list-wrap{
//   background-color: var(--bg);
// }
</style>
